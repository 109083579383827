import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import 'scoutstrap/dist/css/scoutstrap.min.css'
import './App.css'
import simlogo from './sim_logo.jpg'

function App() {
  return (
      <Container className="h-100">
        <Row className="h-100 align-items-center">
          <Col sm={{ span: 6, offset: 3 }} className="text-center">

            <img src={simlogo} class="w-100 mb-3" alt="SIM Logo" />

            {/* <h1 className="text-dark mt-2 mb-0">SIM22</h1>
            <h4 className="text-muted mt-0 mb-4 font-weight-light">Suffolk International Moot 2022</h4> */}

            <p>
              With regret the SIM22 Planning Team announces that SIM22 has been cancelled. The event has been rescheduled for 2025.
            </p>

            <p>
              Please see the <a href="https://www.suffolkscouts.org.uk/8-news/latest-news/827-suffolk-international-moot-2022-sim22">full statement on the Suffolk Scouts website</a>.
            </p>

            <p>
              This website will be updated with details of SIM25 when available. 
            </p>

            <p className="text-muted mt-3 font-weight-lighter font-italic">
               17th April 2021
            </p>

            <a href="https://www.suffolkscouts.org.uk/"><div class="logo-inline-black logo-inline-w200 mt-5"> <h6>Suffolk</h6> </div></a>

            

          </Col>
        </Row>
      </Container>
      );
}

export default App;
